<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Mirror frameworks</h3>

	<p>Some frameworks are imported or loaded outside of <span v-html="app_name"/>. Instead of creating a new duplicate framework, we can mirror that original framework. A mirrored framework is the exact same framework as the original framework including the metadata and GUIds for each item. Multiple times each week, <span v-html="app_name"/> will pull the API of the original framework to check for any changes to the original framework. If there are changes, <span v-html="app_name"/> will automatically update the mirrored framework to match the original framework.</p>

	<p>A mirrored framework can only be edited at the original source.</p>

	<p>Only a System Admin can mirror a framework.</p>

	<p>To mirror a framework:
		<ol>
			<li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> an account with sufficient permissions.</li>
			<li>Towards the top right of the screen, click <strong>+ NEW FRAMEWORK</strong> <img srcset="/docimages/new_framework.png 3x" class="k-help-img"></li>
			<li>Click <strong>Mirror framework from another CASE server</strong></li>
			<li>Find the API url for the server and paste it into the text box where “CFPackages API url” is located.</li>
			<ul>
				<li>Modify the API url as needed to match the example provided above the text box.</li>
				<img srcset="/docimages/import_framework.png" class="k-help-img">
			</ul>
			<li>Verify that <strong>Perform automatic daily update checks from source framework</strong> is checked<img srcset="/docimages/perform_automatically.png" class="k-help-img block"></li>
			<li>Click <strong>IMPORT MIRROR</strong></li>
			<li>A mirrored framework of the original framework will now be created and can be searched or used to associate other standards to as needed.</li>
		</ol>
	</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	